@value variables: "../variables.module.css";
@value breakpoint-sm from variables;
@value breakpoint-lg from variables;

.MeasureList {
  --border: #eee;
  --outline-hover: #0b6aff;
  --background: #f3f3f3;
  --pagination-item-border: #aaa;

  background: var(--white);
  padding: 1rem 2rem;
}

.measure {
  border-bottom: 1px solid var(--border);
}

.measureTitle {
  font-size: 1rem;
}

.link,
.link:hover {
  color: var(--text);
}

@media (max-width: breakpoint-lg) {
  .diamondCol {
    max-width: 20%;
  }
}

@media (max-width: breakpoint-sm) {
  .diamondCol {
    margin: 1rem 0 0 1rem;
  }
}

.disabled {
  opacity: 0.5;
}

.compareButton {
  padding: 0.4rem 1.2rem;
}

.controls {
  float: right;
}

@media (max-width: breakpoint-sm) {
  .controls {
    float: none;
  }

  .compareButton {
    display: block;
    margin-top: 0.5rem;
  }
}

.sortByForm {
  margin-top: 0.2rem;
  vertical-align: top;
}

.sortBy {
  margin-top: 0.2rem;
}

.sortBySelect {
  --border: #ddd;
  --text: #232323;

  background: var(--background);
  border: 1px solid var(--button-purple);
  border-radius: 2px;
  color: var(--text);
  height: 2.1rem;
  padding: 0.3rem 0.5rem;
  width: 9.5rem;
}

.sortBySelect:hover {
  cursor: pointer;
  outline: 1px solid var(--outline-hover);
  outline-offset: 2px;
}

.compareCol {
  max-width: 6.5rem;
  min-width: 6.5rem;
  padding: 1rem 0 0 2.5rem;
}

.compareForm:hover .checkbox,
.compareForm:hover .compareLabel {
  cursor: pointer;
}

.compareForm:hover .checkbox {
  outline: 1px solid var(--outline-hover);
  outline-offset: 1px;
}

.compareForm:hover .compareLabel {
  color: var(--outline-hover);
}

@media (max-width: breakpoint-sm) {
  .compareCol {
    max-width: 100%;
    padding: 1rem 0 0 1.3rem;
    text-align: left !important;
  }

  .compareCol .checkbox {
    display: inline-block;
    margin-right: 0.5rem;
  }
}

.pagination {
  list-style: none;
  text-align: center;
}

.paginationItem {
  display: inline-block;
}

.paginationLink,
.paginationLink:hover {
  background: var(--background);
  border: 1px solid var(--button-purple);
  border-radius: 2px;
  color: var(--button-purple);
  display: block;
  height: 2rem;
  margin-right: 0.25rem;
  padding: 0.15rem 0.4rem;
}

.paginationLink:hover {
  outline: 1px solid var(--outline-hover);
  outline-offset: 1px;
}

.paginationLink.wide {
  width: 5rem;
}

.paginationLink.active {
  background: var(--button-purple);
  color: var(--background);
}

.clearButton {
  --background: white;

  background: var(--background);
  border: none;
  bottom: 0.5rem;
  color: var(--purple);
  position: relative;
  text-decoration: underline;
}

.clearButton:hover {
  text-decoration: none;
}

.numResults {
  font-family: 'Lexend Bold', sans-serif;
}
