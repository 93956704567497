.heading {
  font-family: 'Lexend Bold', sans-serif;
  font-size: 1rem;
}

.sectionHeading {
  font-family: 'Lexend Bold', sans-serif;
  font-size: 1.2rem;
}

.programsButton {
  padding: 0.4rem 1.7rem;
  width: 14rem;
}

.publisherButton {
  padding: 0.4rem 1.7rem;
  width: 14rem;
}
