.AgeRange {
  --line: #999;
}

.heading {
  font-family: 'Lexend Bold', sans-serif;
  font-size: 1rem;
}

.numberLine {
  border-top: 1px solid var(--line);
}

.range {
  --range: #de3281;

  border-bottom: 3px solid var(--range);
  color: var(--range);
  font-size: 0.8rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.tick {
  border-left: 1px solid var(--line);
  height: 0.3rem;
}

.subtick {
  border-left: 1px solid var(--line);
  height: 0.15rem;
}

.tick.last {
  border-right: 1px solid var(--line);
}
