.PageFindMeasures {
  --search-button-width: 8.4rem;
}

.searchWrapper {
  width: calc(100% - var(--search-button-width));
}

[class*='InputIcon-left'] {
  cursor: default !important;
}

[class*='InputIcon-right'] {
  top: 8px !important;
}

.clearSearch {
  display: none;
}

.clearSearch:hover {
  cursor: pointer;
}

/* stylelint-disable-next-line selector-max-type */
.searchBox input {
  border: 0;
}

.PageFindMeasures .searchButton {
  padding: 0.7rem 2rem;
  position: relative;
  top: -0.1rem;
}

:global(.search-icon) {
  position: relative;
  top: -0.35rem;
}
