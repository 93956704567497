@value variables: "../variables.module.css";
@value breakpoint-lg from variables;

.leftArrow {
  color: var(--purple);
  font-size: 1.5rem;
  margin-right: 0.3rem;
}

.backLink,
.backLink:hover {
  color: var(--purple);
}

.measureCol {
  background: white;
}

@media (max-width: breakpoint-lg) {
  .measureCol {
    margin-bottom: 2rem;
  }
}

.measureCol :global(.col-lg) {
  min-width: 100%;
}
