.MeasureScores {
  --pink: #de3281;
  --background: #f4f4f4;

  background: var(--background);
}

.scores {
  font-family: 'Lexend Bold', sans-serif;
  list-style-type: none;
}

.notCompare {
  min-width: 100%;
}

.compare {
  margin-top: 0.4rem;
  min-width: 6rem;
}

.insufficient {
  color: var(--pink);
}

.insufficientMessage {
  font-family: 'Lexend', sans-serif;
  font-size: 0.85rem;
}
