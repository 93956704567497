.App {
  --background: #f7f5f4;
  --purple: #2b095b;
  --link-text: #e20c63;

  background: var(--background);
  color: var(--text);
  padding: 0;
}

.main {
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
  padding: 2rem 2.5rem 4rem;
}
