@value variables: "../variables.module.css";
@value breakpoint-lg from variables;

.SearchFilters {
  --highlight: #0b6aff;

  background: var(--white);
  margin: 0 1.5rem 0 1rem;
  padding: 1rem 1rem 1rem 1.5rem;
}

@media (max-width: breakpoint-lg) {
  .SearchFilters {
    margin: 0 0 1rem 0;
  }
}

.refineSearch {
  display: inline-block;
  font-size: 1rem;
  margin-bottom: 2rem;
}

/* stylelint-disable-next-line selector-max-type */
.SearchFilters label:hover::before {
  outline: 1px solid var(--highlight);
  outline-offset: 1px;
}

/* stylelint-disable-next-line selector-max-type */
.SearchFilters label:hover .itemLabel {
  color: var(--highlight) !important;
}

.clearAll {
  background: white;
  border: none;
  float: right;
  font-size: 0.8rem;
  font-weight: bold;
  text-decoration: underline;
}

.clearAll[disabled],
.clearAll:hover {
  text-decoration: none;
}

.count {
  --count: #9b9b9b;

  color: var(--count);
}

.disabled {
  opacity: 0.2;
  width: 100%;
  height: 100%;
  z-index: 1000;
  pointer-events: 'none';
}
