.leftArrow {
  color: var(--purple);
  font-size: 1.5rem;
  margin-right: 0.3rem;
}

.backLink,
.backLink:hover {
  color: var(--purple);
}
