@value variables: "../variables.module.css";
@value breakpoint-lg from variables;

.preferencesLink,
.preferencesLink:hover {
  color: var(--link-text);
  margin-top: 0.6rem;
}

.preferencesIcon {
  border: 1px dashed var(--purple);
  height: 1rem;
  transform: rotate(45deg);
  width: 1rem;
}

.editWord {
  color: var(--purple);
}

.editWord:hover {
  text-decoration: none;
}

.editWord:not(:hover) {
  text-decoration: underline;
}

:global(.modal-footer) {
  justify-content: unset;
}

:global(.modal-header .close) {
  padding: 1.5rem 0.5rem 0 0.5rem;
}

:global(.modal-header .close):hover {
  font-size: 2rem;
  padding: 1.25rem 0.35rem 0 0.5rem;
}

.save {
  border: 3px dashed var(--purple);
  color: var(--purple);
  font-size: 0.9rem;
  padding: 0.55rem 1.4rem;
}

.save:hover {
  background-color: #ebe8eb;
  border-color: #3e0f49;
  color: #3e0f49;
}

.numInput {
  display: inline-block;
  width: 4rem;
}

.numInput:hover {
  --border: rgb(156, 162, 168);

  border: 2px solid var(--border);
}

.outOfTen {
  font-family: 'Lexend Bold', sans-serif;
}

@media (max-width: breakpoint-lg) {
  .diamondCol {
    margin-top: 2rem;
  }
}
