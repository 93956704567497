.trigger,
.trigger:hover {
  color: var(--text);
  display: block;
  font-family: 'Lexend Bold', sans-serif;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.chevronUp {
  display: inline;
  stroke: var(--text);
}

.chevronDown {
  display: none;
  stroke: var(--text);
}

:global(.collapsed) .chevronUp {
  display: none;
}

:global(.collapsed) .chevronDown {
  display: inline;
}

:global(.collapse ul) {
  padding-right: 0.3rem;
}
