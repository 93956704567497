.description {
  font-size: 1.2rem;
}

.publisherButton {
  padding: 0.4rem 1.7rem;
  width: 14rem;
}

.tallNameCol {
  min-height: 7rem;
}

.tallDescriptionCol {
  min-height: 7rem;
}

.linkedMeasureName:not(:hover) {
  text-decoration: underline solid var(--text);
}
