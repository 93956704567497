/* stylelint-disable selector-max-type */

html {
  font-size: 16px;
}

body {
  --text: #232323;
  --button-purple: #2b095b;
  --button-blue: #009aeb;

  font-family: 'Lexend', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3,
h4,
h5 {
  color: var(--text);
  font-family: 'Lexend Bold', sans-serif;
}

h1 {
  font-size: 1.4rem;
}

h2 {
  font-size: 1.4rem;
}

div {
  color: var(--text);
}

.btn {
  border-radius: 2px;
  font-family: 'Lexend Bold', sans-serif !important;
}

.btn-purple,
.btn-purple:hover,
.btn-purple:focus,
.btn-purple:active {
  background-color: var(--button-purple) !important;
  border-color: var(--button-purple) !important;
  box-shadow: none !important;
}

.btn-blue,
.btn-blue:hover,
.btn-blue:focus,
.btn-blue:active {
  background-color: var(--button-blue) !important;
  border-color: var(--button-blue) !important;
  box-shadow: none !important;
}

input:focus {
  box-shadow: 0 0 0 2px var(--button-purple);
}

.btn:focus,
button:focus {
  outline: 2px solid var(--button-purple) !important;
  outline-offset: 3px;
}

.btn:hover {
  text-decoration: none;
}

.btn:not(:hover):not(.disabled) {
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

.popover {
  border-color: #b8daff;
}

.arrow::before {
  border-top-color: #b8daff !important;
}

.arrow::after {
  border-top-color: #cce5ff !important;
}

.popover-body {
  background: #cce5ff;
  color: var(--text);
}

.nowrap {
  white-space: nowrap;
}
