.heading {
  font-family: 'Lexend Bold', sans-serif;
  font-size: 1rem;
}

.diamondCol {
  max-width: 10rem;
}

.diamond {
  width: 6rem;
}

.relatedLink,
.relatedLink:hover {
  color: var(--text);
}

.relatedName {
  font-size: 1rem;
}
