.welcomeBox {
  color: var(--text);
}

.titleRow {
  height: 2rem;
}

:global(.alert .close) {
  bottom: 0.4rem;
  position: relative;
}

:global(.alert .close):hover {
  bottom: 0.6rem;
  font-size: 2rem;
  left: 0.15rem;
}

.title {
  font-size: 1rem;
}

.link,
.link:hover {
  --link: #3e0f49;

  color: var(--link);
}

.yellowBlock {
  --background: #ebaf3d;

  background: var(--background);
  display: inline-block;
  height: 1rem;
  margin-right: 0.5rem;
  width: 1.5rem;
}

.dashedLine {
  --line: #7e3d88;

  border-top: 2px dashed var(--line);
  display: inline-block;
  height: 0.4rem;
  margin-right: 0.5rem;
  width: 1.5rem;
}

.secondCol {
  margin-top: -2rem;
  max-width: 20rem;
  min-width: 20rem;
}

.scoringDiamond {
  width: 100%;
}

.scoringList {
  list-style-type: none;
  padding: 0;
}

.abbreviation {
  font-family: 'Lexend Bold', sans-serif;
}

/* prevent close button from growing and jumping around */
.toggle {
  font-size: 1.5em !important;
}
.toggle:hover {
  font-size: 1.5em !important;
  bottom: 0.4rem !important;
  left: 0 !important;
}
