.favorites {
  background: white;
}

.favoriteList {
  list-style-type: none;
  width: 70%;
}

.heartCol {
  max-width: 3rem;
}

.name {
  font-family: 'Lexend Bold', sans-serif;
}

.listItem {
  --border: #f4f4f4;

  border-bottom: 1px solid var(--border);
}

.link,
.link:hover {
  color: var(--text);
}
