.Favorite {
  --background: white;
  --heart: #f20080;

  background: var(--background);
  border: none;
  color: var(--heart);
  cursor: pointer;
  font-size: 1.5rem;
}
